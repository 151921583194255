/* global twentyseventeenScreenReaderText */
;(function( $ ) {

    //HomePage
    $.fn.moduleTabs = function() {

        var $list = this;
        var name = $list.selector;
        var $item = $list.find(name + '__item');

        $item.each( function(index, item ) {
            $(item).find(name + '__text' ).finish().slideUp();
            $(item).on('click', function () {
                $(item).find(name + '__text' ).finish().slideToggle();
                $(item).find(name + '__tab-icon' ).toggleClass('open');
            }); 
        });
    };

    $(document).ready(function(){

        // Add header video class after the video is loaded.
        $( document ).on( 'wp-custom-header-video-loaded', function() {
            $body.addClass( 'has-header-video' );
        });

        if( $('body').hasClass('home') && window.innerWidth <= 768 ) {
            $('.function-list').moduleTabs();
        }

        $( '#facts-slider' ).owlCarousel({
            loop: true,
            margin: 10,
            nav: false,
            autoplay: true,
            autoplayHoverPause: true,
            autoplaySpeed: 200,
            responsive:{
                0:{
                    items: 1
                },
                640:{
                    items: 1
                },
                1000:{
                    items: 2
                }
            }
        });






    });





})( jQuery );
